import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Block from '../components/Block';
import ListItem from '../components/ListItem';

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || 'Title';
	const posts = data.allMarkdownRemark.nodes;

	return (
		<Layout location={location} title="Write to me | Roman Musatkin">
			<Block>
				<div style={{textAlign:"center", marginTop:"30vh"}}>
				roman 👌 musatkin.com
				</div>
			</Block>
		</Layout>
	);
};

export default BlogIndex;

const WritingContainer = styled.div`

`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
			title
			date(formatString: "MMM D")
			description
			tags
			author
        }
      }
    }
  }
`;
